<template>
    <div class="customs-page">
        <div class="customs-list-v-panel">
            <div class="customs-list-v-panel__filters ">
                <filter-item
                    v-model="filters[item.model]"

                    v-for="item in filter"
                    :key="item.template"

                    :template="item.template"
                    :type-form="item.typeForm"
                    :filter="item.filter"
                    :filterModel="item.filterModel"
                    :name="item.name"
                    :label="item.label"
                    :placeholder="item.placeholder"

                    class="item"
                />
            </div>
        </div>
        <CustomsList
            :endpoint="$apiUrl.customs.declarations"
            :filter-params="filterParams"
            paginate
            :sort-key="''"
            :ref="tableRef"
        />
    </div>
</template>
<script>
import CustomsList from '@/components/customs/list.vue';
import FilterItem from '~/components/ShipmentsAdmin/FilterItem';
import moment from 'moment';

export default {
    components: {
        CustomsList,
        FilterItem,
    },
    watch: {
        filterParams: {
            handler() {
                this.$refs[this.tableRef].table.fetchRows();
            },
            deep: true,
        },
        filters: {
            handler(val) {
                this.filterParams = Object.entries(val).reduce((accum, entry) => {
                    if (entry[0] === 'date_between') {
                        accum[`filter[${entry[0]}]`] = this.parseRangeDate(entry[1]);
                    }

                    if (entry[0] === 'search' && entry[1]) {
                        accum[`filter[${entry[0]}]`] = entry[1];
                    }

                    if (!this.adminLevel) {
                        accum[`filter[owner_site_from_source]`] = this.user.sitesFilter.map(item => item.key).join(',');
                    }

                    return accum;
                }, {});
            },
            deep: true,
        },
    },
    data() {
        return {
            tableRef: 'cutsoms-table',
            filterParams: {},
            filter: [
                {
                    template: 'date_between',
                    typeForm: 'date',
                    name: 'Date',
                    fullList: true,
                    model: 'date_between',
                    filter: true,
                },
                {
                    template: 'search',
                    typeForm: 'input',
                    name: 'Waybill',
                    model: 'search',
                    filter: true,
                },
            ],
            filters: {
                date_between: {
                    startDate: moment(new Date()),
                    endDate: moment(new Date()),
                },
                search: '',
            }
        };
    },
    computed: {
        adminLevel() {
            return this.$store.getters['auth/isGodUser'];
        },
        user() {
            return this.$store.getters['auth/user'];
        },
    },
    methods: {
        parseRangeDate(dates) {
            if (typeof dates === 'string') {
                return dates;
            }

            if (dates && !dates) {
                return '';
            }

            return dates.startDate.format('YYYY-MM-DD') + ',' + dates.endDate.format('YYYY-MM-DD');
        },
    },
};
</script>
<style lang="scss">
@import 'src/styles/pages/shipments-list.scss';
@import 'src/styles/pages/shipments-list-items.scss';

.customs-page {
    height: 100%;
    overflow: hidden;

    .customs-list-v-panel {
        margin-bottom: 12px;
        padding: 16px;
        border-radius: 24px;
        background: white;

        &__filters {
            display: flex;
            gap: 24px;
        }
    
        .tools {
            &>div:last-child {
                display: none;
            }
        }
    }
    
    .shipments-list {
        height: calc(100vh - 120px);
    }
    
    .shipments-list__item {
        &:first-child {
            margin-top: 0;
        }
    }

    .shipments-list-item {
        min-height: unset;
    }
    
    .customs-list-item {
        gap: 32px;
        padding-left: 16px;
    
        &__title {
            color: #6A7884;
        }
    
        &__col {
            display: flex;
            flex-direction: column;
    
            svg {
                path {
                    fill: #2E457C;
                }
            }
        }
    
        &__delimeter {
            width: 2px;
            height: 50px;
            background: #F7F7F7;
        }
    }
}
</style>